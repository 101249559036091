
const stationItem = (item, count=4) => {



  return `
  <div class="realtime-station-container item reloadable-item" style="height: calc((100vh - 100px)/ ${count})">
    <h3 class="serving">${item.name}</h3>
    <hr style="margin: 4px !important">
    ${
      item.station_queueings_today_ongoing.length > 0 ?
        `<h5 class="title">
          ${item.station_queueings_today_ongoing[0].admission.display_name}
        </h5>`
      :
        `<p class="no-patient" style="margin: 0 !important; color: gray" >
          No patient is being served
        </p>`
    }
    ${
      item.station_queueing_today_pending.length > 0 ?
        `
        <h5>Next</h5>
        <h5 class="title">${item.station_queueing_today_pending[0].admission.display_name}</h5>
        `
      :
       ``
    }
  </div>
  `
}

const renderReloaded = (realtimeContainer, data) => {
  let content = ""
  let filtered_data = data.filter(item => item.station_queueings_today_ongoing.length > 0 || item.station_queueing_today_pending.length > 0)
   filtered_data = data
  filtered_data.map(item => {
    content = content + stationItem(item, Math.ceil(filtered_data.length / 4.0))
  })
  realtimeContainer.append(content)
  console.log({data})
}

const queueingRefresher = () => {
  let realtimeContainer = $("#realtime-container");
  console.log({realtimeContainer})
  if(realtimeContainer.length > 0){
    setInterval(() => {
      fetch("/stations_queueing/stations")
        .then(res => res.json())
        .then(res => {
          document.querySelectorAll('.reloadable-item').forEach(e => e.remove());
          renderReloaded(realtimeContainer, res)
        }).catch(error => {
          console.log({error})
        })
    }, 2000);
  }
}

export default queueingRefresher