// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery'
require("bootstrap")

window.$ = window.jQuery = require('jquery');
import 'bootstrap'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "../stylesheets/application";
import queueingRefresher from './queueing_refresher';
import initPayment from './checkout-payment';
import initYesNoCheckBox from './checkbox_yes_no';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

document.addEventListener("turbolinks:load", function() {
  queueingRefresher()
  initPayment()
  initYesNoCheckBox()
  $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
  })
  
  let timeContainer = $("#time-container")
  console.log({timeContainer})
  if(timeContainer){
    setInterval(() => {
      timeContainer.html(new Date().toDateString() + "\n" + new Date().toLocaleTimeString())
    }, 1000);
  }

  window.onafterprint = function(){
    const admission_id = document.querySelector("#admission_id")?.value
    if(admission_id != null) fetch('/patients/print_admission?id=' + admission_id)

    const ksa_id = document.querySelector("#ksa_id")?.value
    if(ksa_id != null) fetch('/patients/print_ksa_waiver?id=' + ksa_id)

    const pu_id = document.querySelector("#pu_id")?.value
    if(pu_id != null) fetch('/patients/print_pu?id=' + pu_id)

    const medcert_id = document.querySelector("#medcert_id")?.value
    if(medcert_id != null) fetch('/patients/print_medcert?id=' + medcert_id)

    const mmr_certificate_id = document.querySelector("#mmr_certificate_id")?.value
    if(mmr_certificate_id != null) fetch('/patients/print_mmr_certificate_printed_at?id=' + mmr_certificate_id)
  }
})