const loader ={
  show: () => {
    let loader = document.querySelector("#loader-container")
    loader.classList.remove("hide")
  },
  hide: () => {
    let loader = document.querySelector("#loader-container")
    loader.classList.add("hide")
  }
}

export default loader