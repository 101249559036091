import loader from "./loader"

const PAYMENT_METHODS = {
  cash: "CASH",
  paymaya: "PAYMAYA"
}
let selectedPaymentMethod = PAYMENT_METHODS.cash

const initPaymentMethodChecker = () => {
  let paymaya = document.querySelector("#paymaya")
  let cash = document.querySelector("#cash")
  let checkoutBtn = document.querySelector("#checkout")
  let dateOfExamination = document.querySelector("#dateOfExamination")
  console.log({checkoutBtn})
  paymaya.addEventListener('click', () => {
    alert("Not available at the moment")
  })

  checkoutBtn.addEventListener('click', async(e) => {
    e.preventDefault()
    loader.show()
    await fetch('/cart/check_out_order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({date: dateOfExamination.value})
    }).then(res => {
      console.log({res})
      if(res.ok){
        window.location.href = "/results"
      }else{
        alert("Something went wrong")
      }
    }).catch(err => {
      console.log({err})
      alert("Something went wrong")
    })
    loader.hide()
  })
}

const initPayment = () => {
  const pathName = window.location.pathname.split("/")
  if(pathName[pathName.length -1 ] == "check_out"){
    console.log("Checkout")
    initPaymentMethodChecker()
  }
}

export default initPayment
